import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9d374446 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _e04c808c = () => interopDefault(import('../pages/app-download/index.vue' /* webpackChunkName: "pages/app-download/index" */))
const _fe9d1ea2 = () => interopDefault(import('../pages/book/index.vue' /* webpackChunkName: "pages/book/index" */))
const _5b3468b2 = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _2e16f0a1 = () => interopDefault(import('../pages/course/index.vue' /* webpackChunkName: "pages/course/index" */))
const _ff1e1fe2 = () => interopDefault(import('../pages/face-special/index.vue' /* webpackChunkName: "pages/face-special/index" */))
const _bbd89cfe = () => interopDefault(import('../pages/forget-pass/index.vue' /* webpackChunkName: "pages/forget-pass/index" */))
const _57b0ac19 = () => interopDefault(import('../pages/in-development/index.vue' /* webpackChunkName: "pages/in-development/index" */))
const _8f65f28e = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _0f048b33 = () => interopDefault(import('../pages/my-order/index.vue' /* webpackChunkName: "pages/my-order/index" */))
const _aaa937ce = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _628e9669 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _627313b5 = () => interopDefault(import('../pages/shopping-cart/index.vue' /* webpackChunkName: "pages/shopping-cart/index" */))
const _16e456b2 = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _4f9ec4c3 = () => interopDefault(import('../pages/wx-login/index.vue' /* webpackChunkName: "pages/wx-login/index" */))
const _04658b90 = () => interopDefault(import('../pages/about/detail-info/index.vue' /* webpackChunkName: "pages/about/detail-info/index" */))
const _a6690abc = () => interopDefault(import('../pages/agreement/privacy-policy-YAO_XUE.vue' /* webpackChunkName: "pages/agreement/privacy-policy-YAO_XUE" */))
const _0499312e = () => interopDefault(import('../pages/agreement/privacy-policy-ZHI_YE.vue' /* webpackChunkName: "pages/agreement/privacy-policy-ZHI_YE" */))
const _705ad6da = () => interopDefault(import('../pages/agreement/user-agreement-YAO_XUE.vue' /* webpackChunkName: "pages/agreement/user-agreement-YAO_XUE" */))
const _1e3e951d = () => interopDefault(import('../pages/agreement/user-agreement-ZHI_YE.vue' /* webpackChunkName: "pages/agreement/user-agreement-ZHI_YE" */))
const _78d3135c = () => interopDefault(import('../pages/book/book-detail.vue' /* webpackChunkName: "pages/book/book-detail" */))
const _1b64a415 = () => interopDefault(import('../pages/course/detail/index.vue' /* webpackChunkName: "pages/course/detail/index" */))
const _538814d0 = () => interopDefault(import('../pages/h5/course-class/index.vue' /* webpackChunkName: "pages/h5/course-class/index" */))
const _f0d38b96 = () => interopDefault(import('../pages/h5/forget-pass/index.vue' /* webpackChunkName: "pages/h5/forget-pass/index" */))
const _71c1266d = () => interopDefault(import('../pages/h5/login/index.vue' /* webpackChunkName: "pages/h5/login/index" */))
const _59f82b96 = () => interopDefault(import('../pages/h5/register/index.vue' /* webpackChunkName: "pages/h5/register/index" */))
const _6cb9582f = () => interopDefault(import('../pages/news/news-info.vue' /* webpackChunkName: "pages/news/news-info" */))
const _9b59a7c2 = () => interopDefault(import('../pages/news/news-list.vue' /* webpackChunkName: "pages/news/news-list" */))
const _08db4956 = () => interopDefault(import('../pages/order/check-order.vue' /* webpackChunkName: "pages/order/check-order" */))
const _28a74c80 = () => interopDefault(import('../pages/order/check-shop-order.vue' /* webpackChunkName: "pages/order/check-shop-order" */))
const _78c29ab1 = () => interopDefault(import('../pages/register/index-dev.vue' /* webpackChunkName: "pages/register/index-dev" */))
const _07557b6f = () => interopDefault(import('../pages/teacher/teacher-detail/index.vue' /* webpackChunkName: "pages/teacher/teacher-detail/index" */))
const _3c379278 = () => interopDefault(import('../pages/wx-login/middle-page.vue' /* webpackChunkName: "pages/wx-login/middle-page" */))
const _13aefc82 = () => interopDefault(import('../pages/agreement/components/ColumnName.vue' /* webpackChunkName: "pages/agreement/components/ColumnName" */))
const _1acb526e = () => interopDefault(import('../pages/agreement/components/header.vue' /* webpackChunkName: "pages/agreement/components/header" */))
const _1575d92e = () => interopDefault(import('../pages/course/components/course-class.vue' /* webpackChunkName: "pages/course/components/course-class" */))
const _64cb097c = () => interopDefault(import('../pages/course/components/course-class-card/index.vue' /* webpackChunkName: "pages/course/components/course-class-card/index" */))
const _0dbc7d97 = () => interopDefault(import('../pages/course/components/course-face.vue' /* webpackChunkName: "pages/course/components/course-face" */))
const _5f81ec63 = () => interopDefault(import('../pages/course/components/course-plan.vue' /* webpackChunkName: "pages/course/components/course-plan" */))
const _72d91730 = () => interopDefault(import('../pages/course/components/filter-row/index.vue' /* webpackChunkName: "pages/course/components/filter-row/index" */))
const _75dfd338 = () => interopDefault(import('../pages/course/components/live-course.vue' /* webpackChunkName: "pages/course/components/live-course" */))
const _50a5b2f2 = () => interopDefault(import('../pages/course/components/mixins/index.js' /* webpackChunkName: "pages/course/components/mixins/index" */))
const _5af1b54a = () => interopDefault(import('../pages/course/components/share-modal/index.vue' /* webpackChunkName: "pages/course/components/share-modal/index" */))
const _373544a8 = () => interopDefault(import('../pages/course/components/special-class.vue' /* webpackChunkName: "pages/course/components/special-class" */))
const _2b04ad0f = () => interopDefault(import('../pages/course/components/transition/index.js' /* webpackChunkName: "pages/course/components/transition/index" */))
const _e1d093d6 = () => interopDefault(import('../pages/course/components/video-course.vue' /* webpackChunkName: "pages/course/components/video-course" */))
const _72369ce0 = () => interopDefault(import('../pages/order/components/coupon-list.vue' /* webpackChunkName: "pages/order/components/coupon-list" */))
const _3931f140 = () => interopDefault(import('../pages/order/components/order-address.vue' /* webpackChunkName: "pages/order/components/order-address" */))
const _893740aa = () => interopDefault(import('../pages/order/components/wechat-modal.vue' /* webpackChunkName: "pages/order/components/wechat-modal" */))
const _d44eacb6 = () => interopDefault(import('../pages/course/detail/components/top-detail.vue' /* webpackChunkName: "pages/course/detail/components/top-detail" */))
const _01e4065c = () => interopDefault(import('../pages/course/components/filter-row/components/content-type-sel.vue' /* webpackChunkName: "pages/course/components/filter-row/components/content-type-sel" */))
const _062b7caf = () => interopDefault(import('../pages/course/components/filter-row/components/price-sel.vue' /* webpackChunkName: "pages/course/components/filter-row/components/price-sel" */))
const _7e452c60 = () => interopDefault(import('../pages/course/components/filter-row/components/sort-title.vue' /* webpackChunkName: "pages/course/components/filter-row/components/sort-title" */))
const _6472bd9f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c07de926 = () => interopDefault(import('../pages/index/components/book-section/index.vue' /* webpackChunkName: "pages/index/components/book-section/index" */))
const _3da067df = () => interopDefault(import('../pages/index/components/course-section/index.vue' /* webpackChunkName: "pages/index/components/course-section/index" */))
const _01522c30 = () => interopDefault(import('../pages/index/components/free-section/index.vue' /* webpackChunkName: "pages/index/components/free-section/index" */))
const _6953459d = () => interopDefault(import('../pages/index/components/government/index.vue' /* webpackChunkName: "pages/index/components/government/index" */))
const _0eba8873 = () => interopDefault(import('../pages/index/components/index-banner/index.vue' /* webpackChunkName: "pages/index/components/index-banner/index" */))
const _18d326b0 = () => interopDefault(import('../pages/index/components/live-section/index.vue' /* webpackChunkName: "pages/index/components/live-section/index" */))
const _58093444 = () => interopDefault(import('../pages/index/components/news-index/index.vue' /* webpackChunkName: "pages/index/components/news-index/index" */))
const _054fc41e = () => interopDefault(import('../pages/index/components/policy/index.vue' /* webpackChunkName: "pages/index/components/policy/index" */))
const _4753833b = () => interopDefault(import('../pages/index/components/section-major/index.vue' /* webpackChunkName: "pages/index/components/section-major/index" */))
const _56ba2874 = () => interopDefault(import('../pages/index/components/voice-section/index.vue' /* webpackChunkName: "pages/index/components/voice-section/index" */))
const _d2ac6c90 = () => interopDefault(import('../pages/index/components/course-section/child-major-modal/index.vue' /* webpackChunkName: "pages/index/components/course-section/child-major-modal/index" */))
const _c9365e7c = () => interopDefault(import('../pages/index/components/course-section/data.js' /* webpackChunkName: "pages/index/components/course-section/data" */))
const _c8a09ad2 = () => interopDefault(import('../pages/index/components/voice-section/data.js' /* webpackChunkName: "pages/index/components/voice-section/data" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _9d374446,
    name: "about"
  }, {
    path: "/app-download",
    component: _e04c808c,
    name: "app-download"
  }, {
    path: "/book",
    component: _fe9d1ea2,
    name: "book"
  }, {
    path: "/certificate",
    component: _5b3468b2,
    name: "certificate"
  }, {
    path: "/course",
    component: _2e16f0a1,
    name: "course"
  }, {
    path: "/face-special",
    component: _ff1e1fe2,
    name: "face-special"
  }, {
    path: "/forget-pass",
    component: _bbd89cfe,
    name: "forget-pass"
  }, {
    path: "/in-development",
    component: _57b0ac19,
    name: "in-development"
  }, {
    path: "/login",
    component: _8f65f28e,
    name: "login"
  }, {
    path: "/my-order",
    component: _0f048b33,
    name: "my-order"
  }, {
    path: "/news",
    component: _aaa937ce,
    name: "news"
  }, {
    path: "/register",
    component: _628e9669,
    name: "register"
  }, {
    path: "/shopping-cart",
    component: _627313b5,
    name: "shopping-cart"
  }, {
    path: "/teacher",
    component: _16e456b2,
    name: "teacher"
  }, {
    path: "/wx-login",
    component: _4f9ec4c3,
    name: "wx-login"
  }, {
    path: "/about/detail-info",
    component: _04658b90,
    name: "about-detail-info"
  }, {
    path: "/agreement/privacy-policy-YAO_XUE",
    component: _a6690abc,
    name: "agreement-privacy-policy-YAO_XUE"
  }, {
    path: "/agreement/privacy-policy-ZHI_YE",
    component: _0499312e,
    name: "agreement-privacy-policy-ZHI_YE"
  }, {
    path: "/agreement/user-agreement-YAO_XUE",
    component: _705ad6da,
    name: "agreement-user-agreement-YAO_XUE"
  }, {
    path: "/agreement/user-agreement-ZHI_YE",
    component: _1e3e951d,
    name: "agreement-user-agreement-ZHI_YE"
  }, {
    path: "/book/book-detail",
    component: _78d3135c,
    name: "book-book-detail"
  }, {
    path: "/course/detail",
    component: _1b64a415,
    name: "course-detail"
  }, {
    path: "/h5/course-class",
    component: _538814d0,
    name: "h5-course-class"
  }, {
    path: "/h5/forget-pass",
    component: _f0d38b96,
    name: "h5-forget-pass"
  }, {
    path: "/h5/login",
    component: _71c1266d,
    name: "h5-login"
  }, {
    path: "/h5/register",
    component: _59f82b96,
    name: "h5-register"
  }, {
    path: "/news/news-info",
    component: _6cb9582f,
    name: "news-news-info"
  }, {
    path: "/news/news-list",
    component: _9b59a7c2,
    name: "news-news-list"
  }, {
    path: "/order/check-order",
    component: _08db4956,
    name: "order-check-order"
  }, {
    path: "/order/check-shop-order",
    component: _28a74c80,
    name: "order-check-shop-order"
  }, {
    path: "/register/index-dev",
    component: _78c29ab1,
    name: "register-index-dev"
  }, {
    path: "/teacher/teacher-detail",
    component: _07557b6f,
    name: "teacher-teacher-detail"
  }, {
    path: "/wx-login/middle-page",
    component: _3c379278,
    name: "wx-login-middle-page"
  }, {
    path: "/agreement/components/ColumnName",
    component: _13aefc82,
    name: "agreement-components-ColumnName"
  }, {
    path: "/agreement/components/header",
    component: _1acb526e,
    name: "agreement-components-header"
  }, {
    path: "/course/components/course-class",
    component: _1575d92e,
    name: "course-components-course-class"
  }, {
    path: "/course/components/course-class-card",
    component: _64cb097c,
    name: "course-components-course-class-card"
  }, {
    path: "/course/components/course-face",
    component: _0dbc7d97,
    name: "course-components-course-face"
  }, {
    path: "/course/components/course-plan",
    component: _5f81ec63,
    name: "course-components-course-plan"
  }, {
    path: "/course/components/filter-row",
    component: _72d91730,
    name: "course-components-filter-row"
  }, {
    path: "/course/components/live-course",
    component: _75dfd338,
    name: "course-components-live-course"
  }, {
    path: "/course/components/mixins",
    component: _50a5b2f2,
    name: "course-components-mixins"
  }, {
    path: "/course/components/share-modal",
    component: _5af1b54a,
    name: "course-components-share-modal"
  }, {
    path: "/course/components/special-class",
    component: _373544a8,
    name: "course-components-special-class"
  }, {
    path: "/course/components/transition",
    component: _2b04ad0f,
    name: "course-components-transition"
  }, {
    path: "/course/components/video-course",
    component: _e1d093d6,
    name: "course-components-video-course"
  }, {
    path: "/order/components/coupon-list",
    component: _72369ce0,
    name: "order-components-coupon-list"
  }, {
    path: "/order/components/order-address",
    component: _3931f140,
    name: "order-components-order-address"
  }, {
    path: "/order/components/wechat-modal",
    component: _893740aa,
    name: "order-components-wechat-modal"
  }, {
    path: "/course/detail/components/top-detail",
    component: _d44eacb6,
    name: "course-detail-components-top-detail"
  }, {
    path: "/course/components/filter-row/components/content-type-sel",
    component: _01e4065c,
    name: "course-components-filter-row-components-content-type-sel"
  }, {
    path: "/course/components/filter-row/components/price-sel",
    component: _062b7caf,
    name: "course-components-filter-row-components-price-sel"
  }, {
    path: "/course/components/filter-row/components/sort-title",
    component: _7e452c60,
    name: "course-components-filter-row-components-sort-title"
  }, {
    path: "/",
    component: _6472bd9f,
    name: "index",
    children: [{
      path: "components/book-section",
      component: _c07de926,
      name: "index-components-book-section"
    }, {
      path: "components/course-section",
      component: _3da067df,
      name: "index-components-course-section"
    }, {
      path: "components/free-section",
      component: _01522c30,
      name: "index-components-free-section"
    }, {
      path: "components/government",
      component: _6953459d,
      name: "index-components-government"
    }, {
      path: "components/index-banner",
      component: _0eba8873,
      name: "index-components-index-banner"
    }, {
      path: "components/live-section",
      component: _18d326b0,
      name: "index-components-live-section"
    }, {
      path: "components/news-index",
      component: _58093444,
      name: "index-components-news-index"
    }, {
      path: "components/policy",
      component: _054fc41e,
      name: "index-components-policy"
    }, {
      path: "components/section-major",
      component: _4753833b,
      name: "index-components-section-major"
    }, {
      path: "components/voice-section",
      component: _56ba2874,
      name: "index-components-voice-section"
    }, {
      path: "components/course-section/child-major-modal",
      component: _d2ac6c90,
      name: "index-components-course-section-child-major-modal"
    }, {
      path: "components/course-section/data",
      component: _c9365e7c,
      name: "index-components-course-section-data"
    }, {
      path: "components/voice-section/data",
      component: _c8a09ad2,
      name: "index-components-voice-section-data"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
