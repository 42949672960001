/**
 账号相关 - 用户登录、注册、忘记密码接口
 */
import { postAxios, getAxios } from '@/lib/http';

export const accountApi = {

  // 账号密码登录 by listen
  loginByPass (data) {
    return postAxios('/pc/user/login', data);
  },
  // LPA平台用户登录/注册 by listen
  loginLpa (data) {
    return postAxios('/pc/user/login/lpa', data);
  },
  // 短信验证码登录 by listen
  loginBySms (data) {
    return postAxios('/pc/user/login/sms', data);
  },
  // 退出登录 by listen
  logout () {
    return getAxios('/pc/user/logout');
  },
  // 用户注册 by listen
  register (data) {
    return postAxios('/pc/user/register', data);
  },
  // 忘记密码 by listen
  resetPassword (data) {
    return postAxios('/pc/user/reset/password', data);
  },
  // 查询详细资料 by listen
  getUserDetail () {
    return getAxios('/pc/user/info');
  },
  // 重置密码 by listen
  resetPasswordInfo (data) {
    return postAxios('/pc/user/reset/password/info', data);
  }
};
