/*
 * @Description:
 * @Author: hanyu
 * @Date: 2020-03-10 14:40:23
 * @LastEditTime: 2020-08-26 09:15:14
 * @LastEditors: 李云飞
 */
import Cookies from 'js-cookie';
import config from '@/config';
const { cookieExpires } = config;
export const TOKEN_KEY = 'ynysjxjy-token';

export default {
  // 设置token
  setToken: (token) => {
    Cookies.set(TOKEN_KEY, token, {
      expires: cookieExpires
    });
  },
  // 读取token
  getToken: () => {
    const token = Cookies.get(TOKEN_KEY);
    if (token) {
      return token;
    }
    return null;
  },
  // 清除token
  clearToken: () => {
    Cookies.remove(TOKEN_KEY);
  }
};
