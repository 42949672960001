/*
 * @Description:
 * @Author: hanyu
 * @Date: 2020-03-10 14:40:23
 * @LastEditTime: 2020-11-12 15:53:25
 * @LastEditors: hanyu
 */
import { APP_ENV_TYPE_ENUM } from '@/constant/common';
export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: `云南省${APP_ENV_TYPE_ENUM[process.env.TYPE].desc}继续教育平台`,
  /**
   * 版本号
   */
  version: '1.0.0',

  /**
   * @description token在Cookie中存储的天数，默认30天
   */
  cookieExpires: 30,

  /**
   * @description localStorage存储用户信息的字段名
   */
  localUserKey: 'ynysjxjy-pc-userInfo'

};
