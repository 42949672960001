<!--
 * @Description:
 * @Author: lihaifan
 * @Date: 2021/6/17 09:43
-->
<template>
  <div>
    <div class="footer-menu">
      <div class="footer-content">
        <div class="menu-nav border">
          <dl
              v-for="(item,index) in menuList"
              :key="index"
          >
            <dt>{{ item.title }}</dt>
            <dd
                v-for="(childItem,childIndex) in item.childList"
                :key="childIndex"
                @click="openLink(childItem.link)"
            >
              {{ childItem.title }}
            </dd>
          </dl>
        </div>
        <div class="menu-nav">
          <dl>
            <dt>平台信息</dt>
            <dd>
              客服电话：
              <p class="desc" style="margin-right:20px">
                18988468680
              </p>
              监督电话：
              <p class="desc">
                0871-63617313
              </p>
            </dd>
            <dd>
              地址：昆明市高新区科发路616号云南省药品监督管理局1906室
            </dd>
            <!--            <dd>-->
            <!--&lt;!&ndash;https://qun.qq.com/join.html#   可用此生成加入群代码&ndash;&gt;-->
            <!--              学习QQ群：-->
            <!--              <img class="qq" src="/images/index/qq.png" />-->
            <!--            </dd>-->
            <dd>
             <a href="http://www.zhengrenedu.com" target="_blank"> 河南正仁教育科技有限公司版权所有</a>
              <span style="margin-left: 10px">v{{ version }}</span>
            </dd>
            <dd>
              <a href="https://beian.miit.gov.cn" target="_blank">豫ICP 备15034326号-2</a>
            </dd>
          </dl>
        </div>
        <div class="qrcode">
          <!--          <img src="/images/index/index-qrcode.png" />-->
          <!--          <p>关注我们</p>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import ThirdCodeModal from './components/third-modal';

export default {
  name: 'IndexFooter',
  components: {
    ThirdCodeModal
  },
  filters: {},
  props: {},
  data () {
    return {
      version: config.version,
      menuList: [
        {
          title: '友情链接',
          childList: [
            {
              title: '国家药品监督管理局执业药师资格认证中心',
              link: 'http://www.cqlp.org/'
            },
            {
              title: '云南省执业药师协会',
              link: 'http://www.ynysw.org/'
            },
          ]
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    // 点击打开链接
    openLink (link) {
      const tempWindow = window.open('_blank');
      tempWindow.location = link;
    }
  }
};
</script>
<style lang='less' scoped>
.footer-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.footer-menu {
  padding: 45px 0;
  background-color: #1764ce;

  .menu-nav {
    display: flex;

    &.border {
      padding-right: 100px;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    dl {
      margin-right: 66px;

      &:last-child {
        margin-right: 0;
      }

      dt {
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 15px;
      }

      dd {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 20px;
        line-height: 22px;

        .desc {
          font-size: 18px;
        }

        .qq {
          margin-left: 4px;
          width: 100px;
          height: 25px;
        }

        a {
          color: #ffffff;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .qrcode {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > img {
      width: 136px;
      height: 136px;
    }

    > p {
      margin-top: 9px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      letter-spacing: 1px;
    }
  }
}
</style>
