/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-08-25 17:25:47
 * @LastEditors: hanyu
 * @LastEditTime: 2021-03-11 13:50:01
 */
import { postAxios, getAxios } from '@/lib/http';
// import Cookies from '@/lib/cookie';
export const commonApi = {
  // 查询展示图 by lianmin
  queryBanner (data) {
    return postAxios('/common/pic', data);
  },

  // 发送短信验证码
  sendVerify (data) {
    return getAxios(`/common/sms/send/verify/${data.phone}/${data.verifyType}`);
  },

  // 获取图形验证码 by listen
  getCaptcha () {
    return getAxios('/common/captcha');
  }
};
