<!--
 * @Description: 首页浮窗
 * @Author: lihaifan
 * @Date: 2021/6/17 09:30
-->
<template>
  <div id="onlineService" class="online-service">
    <div class="online-item">
      <div class="item-header">
        <img src="/images/index/online/online-kefu-icon.png" />
        <p>客服电话</p>
      </div>
      <div class="item-body">
        <div class="body-item title">
          服务时间9:30-17:30
        </div>
        <div class="body-item">
          18988468680
        </div>
      </div>
    </div>
    <div class="online-item">
      <div class="item-header">
        <img src="/images/index/online/online-phone-icon.png" />
        <p>监督电话</p>
      </div>
      <div class="item-body">
        <div class="body-item">
          0871-63617313
        </div>
      </div>
    </div>
<!--    <div class="online-item">-->
<!--      <div class="item-header">-->
<!--        <img src="/images/index/online/online-app-icon.png" />-->
<!--        <p>APP下载</p>-->
<!--      </div>-->

<!--      <div class="item-body">-->
<!--        <div class="qr-item">-->
<!--          <img :src="`/images/index/online/android-app-qr_${type}.png`" />-->
<!--          <p>安卓手机</p>-->
<!--        </div>-->
<!--        <div class="qr-item">-->
<!--          <img :src="`/images/index/online/ios-app-qr_${type}.png`" />-->
<!--          <p class="ios-down-name">-->
<!--            苹果手机-->
<!--          </p>-->
<!--          &lt;!&ndash;          <a href="/苹果IOS系统APP应用安装授信.docx" ><Icon type="ios-cloud-download" />苹果下载帮助</a>&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;<div class="btn-item feedback">-->
<!--          <p>问卷调查</p>-->
<!--        </div>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>

export default {
  name: 'FloatCustomerService',
  components: {},
  filters: {},
  props: {},
  data () {
    return {};
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo;
    },
    type () {
      return process.env.TYPE;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    window.onscroll = function () {
      const onlineService = document.getElementById('onlineService');
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const nTarget = scrollTop + 150;
      if (onlineService) {
        move(onlineService, parseInt(nTarget));
      }
    };

    let oMoveTimer = null;

    function move (oDom, nTarget) {
      clearInterval(oMoveTimer);
      oMoveTimer = setInterval(function () {
        let speed = (nTarget - oDom.offsetTop) / 6;
        speed = speed > 0 ? Math.ceil(speed) : Math.floor(speed);
        if (oDom.offsetTop === nTarget) {
          clearInterval(oMoveTimer);
          return;
        }
        oDom.style.top = oDom.offsetTop + speed + 'px';
      }, 30);
    }
  },
  methods: {
    // 跳转PC页面
    openPc (path, query) {
      this.$pcRouter.push({ path, query });
    }
  }
};
</script>
<style lang='less' scoped>
@import "float-customer-service.less";
</style>
