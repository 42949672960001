/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-24 10:03:32
 * @LastEditors: hanyu
 * @LastEditTime: 2021-05-18 11:32:30
 */
import { postAxios, getAxios } from '@/lib/http';

export const courseApi = {
  // PC全部课程列表 by lianmin
  queryList (data) {
    return postAxios('/pc/index/course/new/list', data);
  },

  // PC首页-免费课程 by liyingwu
  queryFreeList (data) {
    const majorId = data.majorId;
    const showNum = data.showNum;
    return getAxios(`/pc/index/course/new/free/${majorId}/${showNum}`);
  },

  // PC首页-精品直播列表 by liyingwu
  queryIndexLive () {
    return getAxios('/pc/index/new/live/resource');
  },

  // PC前端获取直播/回放url by lianmin
  queryLivePlayUrl (data) {
    return postAxios('/pc/course/live/play/url', data);
  },

  // 视频课 - 课程详情 by liyingwu
  getCourseVideoDetail (courseId) {
    return getAxios(`/pc/course/video/new/detail/${courseId}`);
  },
  // 直播课 - 课程详情 by liyingwu
  getCourseLiveDetail (courseId) {
    return getAxios(`/pc/course/live/new/detail/${courseId}`);
  },
  // 精品班 - 课程详情 by liyingwu
  getCourseClassDetail (courseId) {
    return getAxios(`/pc/course/class/new/detail/${courseId}`);
  },
  // 旗舰班 - 课程详情 by liyingwu
  getCoursePlanDetail (courseId) {
    return getAxios(`/pc/course/plan/new/detail/${courseId}`);
  },
  // 面授班 - 课程详情 by liyingwu
  getCourseOfflineDetail (courseId) {
    return getAxios(`/pc/course/offline/new/detail/${courseId}`);
  },
  // 查询直播资源详情 (学习 - 直播专项课 - 观看页 by liyingwu)
  queryLiveResourceDetail (data) {
    return postAxios('/pc/user/course/live', data);
  },

  // 【新】查询学员最后一次学习位置 by liyingwu
  queryLastCourseRecord () {
    return getAxios('/pc/user/course/last/record');
  },

  // 推荐课程【新】 by liyingwu
  queryRecommend (data) {
    return postAxios('/pc/course/recommend/new/query', data);
  },

  // 课程资源对应的课程班是否下架判断 true：下架 by yandy
  judgeCourseOffShelf (courseResourceId) {
    return getAxios(`/pc/user/course/resource/off/shelf/judge/${courseResourceId}`);
  },
  // 查询课程班课程列表 by yandy
  queryCourseList: (data) => {
    return postAxios('/pc/course/classes/list', data);
  },
};
