<!--
 * @Description: 登录弹窗
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 08:26:49
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-10-22 10:54:13
-->
<template>
  <Modal class-name="account"
         :value="showAccountModal"
         width="430"
         footer-hide
         @on-visible-change="onVisibleChange">
    <AccountContainer ref="accountContainer" @success="loginSuccess"></AccountContainer>
  </Modal>
</template>

<script>
import { accountApi } from '@/api/account';
import { getGeo } from '@/lib/geo';
import { DEVICE_TYPE_ENUM, TERMINAL_TYPE_ENUM } from '@/constant/account';
import AccountContainer from './components/account-container';

export default {
  name: 'Account',
  components: {
    AccountContainer
  },
  filters: {},
  props: {},
  data () {
    return {};
  },
  computed: {
    showAccountModal () {
      return this.$store.state.user.showAccountModal;
    }
  },
  watch: {
    showAccountModal (val) {
      if (val) {
        this.$refs.accountContainer.initListenEnterKeyDown();
      } else {
        this.$refs.accountContainer.unListenEnterKeyDown();
        this.$refs.accountContainer.resetParams();
      }
    }
  },
  created () {
  },
  mounted () {
    // 从url上获取params字段 base64加密
    const params = this.$route.query.params;
    if (params) {
      this.loginLpa(params);
    }
  },
  methods: {
    async loginLpa (data) {
      this.$Spin.show();
      try {
        // 增加 经纬度、终端类型、设备类型 by 李晓冬
        const geoInfo = getGeo();
        const geoArr = (geoInfo.userGeo || '').split(',') || ['', ''];
        const params = {
          data,
          deviceType: DEVICE_TYPE_ENUM.PC.value,
          terminalType: TERMINAL_TYPE_ENUM.PC.value,
          latitude: geoArr[0],
          longitude: geoArr[1]
        };
        const result = await accountApi.loginLpa(params);
        const userInfo = result.data;
        this.$store.commit('user/saveUserInfo', userInfo);
        this.loginSuccess();
        const query = this.$route.query;
        delete query.params;
        const resolve = this.$router.resolve({
          name: this.$route.name,
          query
        });
        location.replace(resolve.href);
      } catch (e) {
        this.$smartSentry.captureException(e);
      } finally {
        this.$Spin.hide();
      }
    },
    // 登录成功
    loginSuccess () {
      this.$store.commit('user/changeAccountModal', { isShow: false });
    },
    // 显示状态发生变化时触发
    onVisibleChange (value) {
      if (!value) {
        this.$store.commit('user/changeAccountModal', { isShow: false });
      }
    }
  }
};
</script>
<style lang='less' scoped>
/deep/ .account {
  display: flex;

  .ivu-modal {
    top: 0;
    margin: auto;

    .ivu-modal-content {
      width: 430px;
      height: 490px;
      border-radius: 4px;

      .ivu-modal-body {
        padding: 40px;
        height: 100%;
      }
    }
  }
}
</style>
