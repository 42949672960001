<!--
 * @Description: 首页浮窗
 * @Author: lihaifan
 * @Date: 2021/6/17 09:30
-->
<template>
  <div v-if="loading">
    <!-- 根据分辨率的不同，判读显示全浮窗 还是mini浮窗 -->
    <FloatCustomerServiceMini v-if="showMini"/>
    <FloatCustomerService v-else/>
  </div>
</template>

<script>
import FloatCustomerService from './components/float-customer-service';
import FloatCustomerServiceMini from './components/float-customer-service-mini';

export default {
  name: 'Index',
  components: { FloatCustomerService, FloatCustomerServiceMini },
  filters: {},
  props: {},
  data () {
    return {
      showMini: false,
      loading: false
    };
  },
  computed: {
  },
  watch: {},
  created () {
  },
  mounted () {
    const screenWidth = screen.height;
    console.log('width', screenWidth);
    console.log('height', screen.height)
    this.showMini = false;
    if (screenWidth < 1024) {
      this.showMini = true;
    }
    this.loading = true;
  },
  methods: {
  }
};
</script>
<style lang='less' scoped>
</style>
