<template>
  <div class="header">
    <div class="header-container" :style="{backgroundImage:'url(/images/index/index-banner_'+type+'.jpg)'}">
      <div class="content">
        <img :src="`/images/index/index-logo.png`" @click="goHome">
        <div class="content-desc">
          <p>监督管理机构：云南省执业药师注册中心</p>
          <p>客服电话：18988468680</p>
        </div>
      </div>
    </div>
    <!-- 公告 -->
    <!--      <div v-show="noticeList.length > 0" class="notice">-->
    <!--        <div class="notice-container">-->
    <!--          <div class="notice-icon">-->
    <!--            最新公告-->
    <!--          </div>-->
    <!--          <div id="noticeSwiper" class="swiper-container swiper-container-vertical">-->
    <!--            <div class="swiper-wrapper">-->
    <!--              <div v-for="(item,index) in noticeList" :key="index" class="swiper-slide">-->
    <!--                <a class="notice-item" :href="'/news/news-info?newsId='+item.newsId" target="_blank">-->
    <!--                  {{ item.newsTitle }}-->
    <!--                </a>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

  </div>
</template>
<script>
import { newsApi } from '@/api/news';

export default {
  name: '',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  async asyncData ({ app, store }) {
    // 公告
    // 快讯
    const noticeResult = await newsApi.queryNewsList({ pageNum: 1, pageSize: 5 });
    const noticeList = noticeResult.data.list;
    return {
      noticeList
    };
  },
  data () {
    return {
    };
  },
  computed: {
    type () {
      return process.env.TYPE;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {
    goHome () {
      this.$router.push({
        path: '/'
      });
    }
  }
};
</script>
<style lang="less" scoped>
.header {
  background: #f2f7ff;

  .header-container {
    background: url("/images/index/index-banner.jpeg") center/cover no-repeat;
    display: flex;
    justify-content: center;

    .content {
      width: 1200px;
      height: 264px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      > img {
        cursor: pointer;
        width: 638px;
        height: 110px;
      }

      .content-desc {
        margin-left: 127px;
        font-size: 18px;
        font-weight: 400;
        color: #1c2634;

        > p {
          margin-top: 10px;
        }
      }
    }
  }
}

.notice {
  width: 1200px;
  height: 34px;
  margin: 0 auto;

  .notice-container {
    margin-left: auto;
    height: 34px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .notice-icon {
      width: 60px;
      height: 24px;
      background: linear-gradient(270deg, #f7716b 93%, #ec4b46 8%);
      border-radius: 2px;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      color: #ffffff;
      line-height: 24px;
    }

    .swiper-container {
      height: 34px;
      margin-left: 8px;

      .swiper-slide {
        height: 34px;
        line-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
          color: #666;

          &:hover {
            color: #333;
          }
        }
      }
    }
  }
}
</style>
