<!--
 * @Description: 绑定手机号 页面
 * @Author: hanyu
 * @Date: 2020-11-12 10:57:07
 * @LastEditTime: 2020-12-30 10:11:42
 * @LastEditors: hanyu
-->
<template>
  <Modal v-model="show" footer-hide>
    <div class="bind-phone-box">
      <div class="title">
        绑定手机号
      </div>
      <div class="form">
        <div class="form-row phone">
          <div class="input-content">
            <Input v-model.trim="formData.phone"
              placeholder="请输入手机号码"
              maxlength="11" />
          </div>
        </div>
        <div class="form-row sms">
          <div class="input-content">
            <Input v-model.trim="formData.smsCode"
              placeholder="请输入验证码"
              maxlength="6" />
          </div>
          <div class="get-sms"
            :class="{disable: disableGetBut}"
            @click="sendVerify">
            {{ getSmsTips }}
          </div>
        </div>
        <div class="send-button"
          @click="registerValidate">
          绑定并登录
        </div>
      </div>
      <Spin v-if="isLoading"
        fix>
        <Icon type="ios-loading"
          size="36"
          class="demo-spin-icon-load" />
      </Spin>
    </div>
  </Modal>
</template>

<script>
import { commonApi } from '@/api/common';
import { accountApi } from '@/api/account';
import { regular } from '@/lib/regular';
import {
  SMS_VERIFY_ACTION_TYPE_ENUM,
  SMS_VERIFY_TYPE_ENUM
} from '@/constant/common';
import { ACCOUNT_SHOW_TYPE, USER_THIRD_TYPE_ENUM, TERMINAL_TYPE_ENUM, DEVICE_TYPE_ENUM } from '@/constant/account';
import { getGeo } from '@/lib/geo';

export default {
  layout: 'empty',
  name: 'BindPhone',
  components: {},
  data () {
    // 这里存放数据
    return {
      registerData: {}, // 微信登录拿到的信息
      show: false,
      login: false,
      formData: {
        phone: null,
        smsCode: null
      },
      passHide: true,
      isLoading: false,
      getSmsTips: '获取验证码',
      disableGetBut: false,
      countDownTimer: null // 定时器
    };
  },
  beforeDestroy () {
    this.unListenEnterKeyDown();
  },
  mounted () {
    // 修改body 默认宽度
    document.body.style.minWidth = 'auto';
  },
  methods: {
    showModal (data) {
      console.log(data);
      this.show = true;
      this.registerData = data;
    },
    // 监听回车按下事件
    listenEnterKeyDown () {
      document.onkeydown = (e) => {
        const event = e || event;
        if (event.keyCode == 13) {
          event.preventDefault();
          this.registerValidate();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown () {
      document.onkeydown = undefined;
    },
    // 切换到登录
    changeLoginType () {
      this.$store.commit(
        'user/changeAccountType',
        ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
      );
    },

    // 发送验证码
    async sendVerify () {
      if (this.disableGetBut) {
        return;
      }
      try {
        this.isLoading = true;
        if (!this.formData.phone) {
          this.$Message.error('请输入手机号码');
          return;
        }
        if (!regular.phone.test(this.formData.phone)) {
          this.$Message.error('手机号码不正确');
          return;
        }
        const data = {
          phone: this.formData.phone,
          checkData: {
            thirdType: 2
          },
          actionType: SMS_VERIFY_ACTION_TYPE_ENUM.BIND_THIRD_ACCOUNT.value,
          verifyType: SMS_VERIFY_TYPE_ENUM.AUTHENTICATION.value
        };
        await commonApi.sendVerify(data);
        this.runCountDown();
        this.$Message.success('验证码已发送');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 开始倒计时
    runCountDown () {
      this.disableGetBut = true;
      let countDown = 60;
      this.getSmsTips = `${countDown}秒重新获取`;
      this.countDownTimer = setInterval(() => {
        if (countDown > 1) {
          countDown--;
          this.getSmsTips = `${countDown}秒重新获取`;
        } else {
          clearInterval(this.countDownTimer);
          this.disableGetBut = false;
          this.getSmsTips = '获取验证码';
        }
      }, 1000);
    },

    // 点击注册，验证注册信息
    async registerValidate () {
      if (!this.formData.phone) {
        this.$Message.error('请输入手机号码');
        return;
      }
      if (!regular.phone.test(this.formData.phone)) {
        this.$Message.error('手机号码不正确');
        return;
      }
      if (!this.formData.smsCode) {
        this.$Message.error('请输入验证码');
        return;
      }
      this.$Spin.show();
      try {
        // 增加 经纬度、终端类型、设备类型 by 李晓东
        const geoInfo = getGeo();
        const geoArr = (geoInfo.userGeo || '').split(',') || ['', ''];
        const params = {
          accessToken: this.registerData.accessToken,
          avatar: this.registerData.headImg,
          gender: this.registerData.gender,
          nickName: this.registerData.nickname,
          phone: this.formData.phone,
          registerPageType: null,
          smsCode: this.formData.smsCode,
          thirdType: USER_THIRD_TYPE_ENUM.WE_CHAT.value,
          unionId: this.registerData.unionId,
          userTerminalType: TERMINAL_TYPE_ENUM.WX_OFFICIAL_ACCOUNT.value,
          latitude: geoArr[0],
          longitude: geoArr[1],
          registerDeviceType: DEVICE_TYPE_ENUM.PC.value
        };
        console.log(params);
        const { data } = await accountApi.userThirdRegister(params);
        this.$store.commit('user/saveUserInfo', data);
        this.show = false;
        // 登录成功， 走登录成功逻辑
        this.$emit('success');
      } catch (e) {
        console.error(e);
        this.$smartSentry.captureException(e);
      }
      this.$Spin.hide();
    },

    // 注册API
    async register () {
      try {
        this.isLoading = true;
        const result = await accountApi.register(this.formData);
        console.log(result);
        this.$emit('loginSuccess', result.data);
        this.$Message.success('注册成功');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 重置参数
    resetParams () {
      clearInterval(this.countDownTimer);
      Object.assign(this.$data, this.$options.data.call(this));
    }
  }
};
</script>
<style lang='less' scoped>
.bind-phone-box {
    width: 400px;
    margin: 0 auto;
    padding-top: 20px;
}
.to-login {
    margin-top: 15px;
    text-align: right;
    a {
        color: @main-color;
    }
}
.title {
    font-size: 18px;
    margin-bottom: 15px;
}
.change-login-type {
    text-align: right;
    margin-bottom: 15px;
    a {
        color: #999;
    }
}
/deep/.form {
    .form-row {
        height: 50px;
        padding-left: 48px;
        padding-right: 10px;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 4px;
        margin-bottom: 20px;
        background-repeat: no-repeat;
        background-position: 17px center;
        display: flex;
        align-items: center;
        &.phone {
            background-image: url('~@/assets/image/account/phone.png');
        }
        &.sms {
            padding-left: 20px;
            padding-right: 0;
        }
        &.user-name {
            background-image: url('~@/assets/image/account/user-name.png');
        }
        &.pass {
            background-image: url('~@/assets/image/account/pass.png');
        }
        .input-content {
            flex: 1;
            overflow: hidden;
            input {
                display: block;
                padding: 0;
                border: none;
                box-shadow: none;
            }
        }
        .get-sms {
            margin-left: 10px;
            width: 120px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            border-left: 1px solid #ededed;
            color: @main-color;
            cursor: pointer;
            &.disable {
                color: #c5c8ce;
                background-color: #f8f8f9;
                cursor: not-allowed;
            }
        }
        .toggle-pass {
            width: 40px;
            height: 40px;
            cursor: pointer;
            background: url('~@/assets/image/account/pass-hide.png') center
                no-repeat;
            &.show {
                background-image: url('~@/assets/image/account/pass-show.png');
            }
        }
    }
    .send-button {
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: @main-color;
        border-radius: 4px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        margin-bottom: 20px;
        margin-top: 30px;
    }
}
</style>
