import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { buryPointApi } from '@/api/bury-point';
import { TERMINAL_TYPE_ENUM } from '../constant/account';

const buryPoint = {
  install (Vue) {
    Vue.prototype.$buryPoint = async (title, content, extData) => {
      try {
        const userInfo = $nuxt.$store.getters['user/userInfo'];
        if (_.isEmpty(userInfo)) {
          return;
        }
        await buryPointApi.reportBuryPoint({
          title,
          content,
          extData,
          terminalType: TERMINAL_TYPE_ENUM.PC.value
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    };
  }
};

Vue.use(buryPoint);
