<!--
 * @Description: 密码登录
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 08:44:38
 * @LastEditors: hanyu
 * @LastEditTime: 2020-10-08 13:49:30
-->
<template>
  <div>
    <div class="form">
<div class="form-row" style="border: none;padding: 0">
        <Select v-model="userType" placeholder="请选择学员类型">
          <Option v-for="item in USER_REGISTER_TYPE_ENUM" :key="item.value" :value="item.value">
{{ item.desc }}
</Option>
        </Select>
      </div>
      <div class="validate-msg">
{{ userTypeError }}
</div>
      <div class="form-row" :class="phoneError?'error':''">
        <img src="/images/index/input-icon/input-phone-icon.png" />
        <div class="input-content">
          <Input
            v-model.trim="formData.account"
            placeholder="请输入您的身份证号码"
          />
        </div>
      </div>
      <div class="validate-msg">
{{ phoneError }}
</div>
      <!--<div class="form-row">
        <img src="/images/index/input-icon/input-id-icon.png"/>
        <div class="input-content">
          <Input
            v-model.trim="formData.idCard"
            placeholder="请输入您的身份证号码"
          />
        </div>
      </div>-->
      <div class="form-row" :class="pwdError?'error':''">
        <img src="/images/index/input-icon/input-pwd-icon.png" />
        <div class="input-content">
          <Input
            v-model.trim="formData.password"
            type="text"
            :style="{'webkit-text-security' : passHide ? 'disc' : 'unset','-moz-text-security' : passHide ? 'disc' : 'unset','text-security' : passHide ? 'disc' : 'unset'}"
            placeholder="请输入您的密码"
          />
        </div>
        <div
          class="toggle-pass"
          :class="{show: !passHide}"
          @click="passHide = !passHide"
        />
<!--        <div class="pwd-tips">-->
<!--          老学员密码为身份证后6位-->
<!--        </div>-->
      </div>
      <div class="validate-msg">
{{ pwdError }}
</div>
      <div
        class="send-button"
        @click="loginBuyPass"
      >
        登 录
      </div>
    </div>

    <Spin
      v-if="isLoading"
      fix
    >
      <Icon
        type="ios-loading"
        size="36"
        class="demo-spin-icon-load"
      />
    </Spin>
  </div>
</template>

<script>
import { DEVICE_TYPE_ENUM, TERMINAL_TYPE_ENUM, USER_REGISTER_TYPE_ENUM } from '@/constant/account';
import { getGeo } from '@/lib/geo';
import { accountApi } from '@/api/account';

export default {
  name: 'AccountLoginPass',
  components: {},
  filters: {},
  props: {},
  data () {
    return {
      USER_REGISTER_TYPE_ENUM,
      passHide: true,
      formData: {
        idCard: null,
        account: null,
        password: null,
        validateNum: null
      },
      isLoading: false,
      phoneError: '',
      pwdError: '',
      userTypeError: '',
      userType: null
    };
  },
  computed: {},
  watch: {
    'formData.account' (val) {
      this.phoneError = '';
      if (!this.formData.account) {
        this.phoneError = '请输入身份证号码';
      }
    },
    'formData.password' (val) {
      this.pwdError = '';
      if (!this.formData.password) {
        this.pwdError = '请输入密码';
      }
    },
    'userType' (val) {
      this.userTypeError = '';
      if (!this.userType) {
        this.userTypeError = '请选择学员类型';
      }
    },
    // 监听是否密码
    passHide: {
      handler (val) {
        this.initInputPassword();
      },
      deep: true
    }

  },
  created () {
  },
  mounted () {
    // 初始化隐藏输入内容
    this.initInputPassword();
  },
  beforeDestroy () {
    this.unListenEnterKeyDown();
  },
  methods: {
    //
    initInputPassword () {
      const x = document.getElementsByTagName('input')[2]; // 第3个input
      const style = window.getComputedStyle(x);
      // console.log(style);

      if (style.webkitTextSecurity) {
        // Do nothing
      } else if (this.passHide) { // 是否隐藏密码
        x.setAttribute('type', 'password');
      } else {
        x.setAttribute('type', 'text');
      }
    },
    // 监听回车按下事件
    listenEnterKeyDown () {
      document.onkeydown = (e) => {
        const event = e || event;
        if (event.keyCode == 13) {
          event.preventDefault();
          this.loginBuyPass();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown () {
      document.onkeydown = undefined;
    },

    // 点击登录
    async loginBuyPass () {
      try {
        this.isLoading = true;
        if (!this.userType) {
          this.userTypeError = '请选择学员类型';
          this.$Message.error('请选择学员类型');
          return;
        }
        if (!this.formData.account) {
          this.phoneError = '请输入身份证号码';
          this.$Message.error('请输入身份证号码');
          return;
        }
        if (!this.formData.password) {
          this.pwdError = '请输入密码';
          this.$Message.error('请输入密码');
          return;
        }
        // 增加 经纬度、终端类型、设备类型 by 李晓冬
        const geoInfo = getGeo();
        const geoArr = (geoInfo.userGeo || '').split(',') || ['', ''];
        const param = {
          ...this.formData,
          deviceType: DEVICE_TYPE_ENUM.PC.value,
          terminalType: TERMINAL_TYPE_ENUM.PC.value,
          latitude: geoArr[0],
          longitude: geoArr[1]
        };
        const result = await accountApi.loginByPass(param);
        this.$emit('loginSuccess', result.data);
        this.$Message.success('登录成功');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 重置参数
    resetParams () {
      Object.assign(this.$data, this.$options.data.call(this));
    }

  }
};
</script>
<style lang='less' scoped>
  @import "./account.less";

  .forget-pass {
    margin-top: 15px;
    text-align: right;

    a {
      color: #999;
    }
  }
  /deep/ .ivu-select-single .ivu-select-selection{
    line-height: 50px;
    height: 50px;
  }

  /deep/ .ivu-select-selection .ivu-select-selected-value {
    line-height: 50px;
    height: 50px;
  }

  /deep/ .ivu-select-selection .ivu-select-placeholder {
    line-height: 50px;
    height: 50px;
  }
</style>
