import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_3178723a from 'nuxt_plugin_sentryserver_3178723a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_fceb554a from 'nuxt_plugin_sentryclient_fceb554a' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_axios_457e961f from 'nuxt_plugin_axios_457e961f' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_axios_2228ef02 from 'nuxt_plugin_axios_2228ef02' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_constant_35ea6598 from 'nuxt_plugin_constant_35ea6598' // Source: ../plugins/constant (mode: 'all')
import nuxt_plugin_iview_2298c11a from 'nuxt_plugin_iview_2298c11a' // Source: ../plugins/iview (mode: 'all')
import nuxt_plugin_vant_925e8cb6 from 'nuxt_plugin_vant_925e8cb6' // Source: ../plugins/vant (mode: 'all')
import nuxt_plugin_polyfill_793e4943 from 'nuxt_plugin_polyfill_793e4943' // Source: ../plugins/polyfill (mode: 'all')
import nuxt_plugin_iscroll_553d2c92 from 'nuxt_plugin_iscroll_553d2c92' // Source: ../plugins/iscroll.js (mode: 'client')
import nuxt_plugin_lodash_5d4c7b8c from 'nuxt_plugin_lodash_5d4c7b8c' // Source: ../plugins/lodash.js (mode: 'all')
import nuxt_plugin_personalcenterrouter_e5889066 from 'nuxt_plugin_personalcenterrouter_e5889066' // Source: ../plugins/personal-center-router.js (mode: 'client')
import nuxt_plugin_smartsentry_2428bc7e from 'nuxt_plugin_smartsentry_2428bc7e' // Source: ../plugins/smart-sentry.js (mode: 'all')
import nuxt_plugin_storeCache_808829d2 from 'nuxt_plugin_storeCache_808829d2' // Source: ../plugins/storeCache (mode: 'client')
import nuxt_plugin_filters_98405076 from 'nuxt_plugin_filters_98405076' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_vueswiper_0a7132d6 from 'nuxt_plugin_vueswiper_0a7132d6' // Source: ../plugins/vue-swiper (mode: 'client')
import nuxt_plugin_vueqr_301d3a84 from 'nuxt_plugin_vueqr_301d3a84' // Source: ../plugins/vue-qr (mode: 'client')
import nuxt_plugin_errorhandle_117ac803 from 'nuxt_plugin_errorhandle_117ac803' // Source: ../plugins/error-handle (mode: 'client')
import nuxt_plugin_burypoint_68002fd3 from 'nuxt_plugin_burypoint_68002fd3' // Source: ../plugins/bury-point (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"云南省执业药师、药学专业人员继续教育平台","meta":[{"charset":"utf-8"},{"http-equiv":"X-UA-Compatible","content":"IE=edge,chrome=1"},{"hid":"description","name":"description"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"script":[{"src":"https:\u002F\u002Fres.wx.qq.com\u002Fconnect\u002Fzh_CN\u002Fhtmledition\u002Fjs\u002FwxLogin.js"},{"src":"https:\u002F\u002Fcdn.bootcdn.net\u002Fajax\u002Flibs\u002Fjquery\u002F3.5.1\u002Fjquery.min.js"},{"src":"https:\u002F\u002Fcdn.bootcdn.net\u002Fajax\u002Flibs\u002Fqrcodejs\u002F1.0.0\u002Fqrcode.min.js"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_3178723a === 'function') {
    await nuxt_plugin_sentryserver_3178723a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_fceb554a === 'function') {
    await nuxt_plugin_sentryclient_fceb554a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_457e961f === 'function') {
    await nuxt_plugin_axios_457e961f(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2228ef02 === 'function') {
    await nuxt_plugin_axios_2228ef02(app.context, inject)
  }

  if (typeof nuxt_plugin_constant_35ea6598 === 'function') {
    await nuxt_plugin_constant_35ea6598(app.context, inject)
  }

  if (typeof nuxt_plugin_iview_2298c11a === 'function') {
    await nuxt_plugin_iview_2298c11a(app.context, inject)
  }

  if (typeof nuxt_plugin_vant_925e8cb6 === 'function') {
    await nuxt_plugin_vant_925e8cb6(app.context, inject)
  }

  if (typeof nuxt_plugin_polyfill_793e4943 === 'function') {
    await nuxt_plugin_polyfill_793e4943(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_iscroll_553d2c92 === 'function') {
    await nuxt_plugin_iscroll_553d2c92(app.context, inject)
  }

  if (typeof nuxt_plugin_lodash_5d4c7b8c === 'function') {
    await nuxt_plugin_lodash_5d4c7b8c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_personalcenterrouter_e5889066 === 'function') {
    await nuxt_plugin_personalcenterrouter_e5889066(app.context, inject)
  }

  if (typeof nuxt_plugin_smartsentry_2428bc7e === 'function') {
    await nuxt_plugin_smartsentry_2428bc7e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_storeCache_808829d2 === 'function') {
    await nuxt_plugin_storeCache_808829d2(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_98405076 === 'function') {
    await nuxt_plugin_filters_98405076(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueswiper_0a7132d6 === 'function') {
    await nuxt_plugin_vueswiper_0a7132d6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueqr_301d3a84 === 'function') {
    await nuxt_plugin_vueqr_301d3a84(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_errorhandle_117ac803 === 'function') {
    await nuxt_plugin_errorhandle_117ac803(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_burypoint_68002fd3 === 'function') {
    await nuxt_plugin_burypoint_68002fd3(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
