import { isPCBroswer } from '../lib/utils';

export default function (context) {
  context.userAgent = process.server ? context.req.headers['user-agent'] : navigator.userAgent;
  const path = context.route.path;
  const query = context.route.query;
  const isH5 = path.includes('/h5/') || query.h5Flag;
  if (isH5 && isPCBroswer(context.userAgent)) {
    context.redirect(301, '/');
  }
  if (!isH5 && /Android|webOS|iPhone|iPod|BlackBerry/i.test(context.userAgent)) {
    context.redirect(301, '/h5/login');
  }
}
