<template>
  <div class="account-container">
    <Tabs @on-click="clickTabs">
      <TabPane label="账号密码登录" :name="ACCOUNT_SHOW_TYPE.LOGIN_PASS.value.toString()">
        <!-- 密码登录 -->
        <LoginPass ref="loginPass"
                   @loginSuccess="loginSuccess" />
      </TabPane>
      <!--<TabPane label="手机验证码登录" :name="ACCOUNT_SHOW_TYPE.LOGIN_SMS.value.toString()">
        &lt;!&ndash; 验证码登录 &ndash;&gt;
        <LoginSms ref="loginSms"
                  @loginSuccess="loginSuccess" />
      </TabPane>-->
    </Tabs>
    <div class="lower">
      <a class="register" href="javascript:;" @click="changeRegister">
        没有账号，去注册
      </a>
      <a class="forget-pwd" href="javascript:;" @click="changeForgetPass">忘记密码？</a>
    </div>
    <!-- 微信登录弹窗 -->
    <WxLogin
        v-if="showWxLoginType && (showAccountType===ACCOUNT_SHOW_TYPE.LOGIN_PASS.value || showAccountType===ACCOUNT_SHOW_TYPE.LOGIN_SMS.value)"
        ref="wxLogin" @success="loginSuccess"></WxLogin>
  </div>
</template>
<script>
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import WxLogin from '../wx-login';
import LoginSms from './login-sms';
import LoginPass from './login-pass';
import Register from './register';
import ForgetPass from './forget-pass';

export default {
  name: 'AccountContainer',
  components: {
    LoginPass,
    LoginSms,
    Register,
    ForgetPass,
    WxLogin
  },
  filters: {},
  mixins: [],
  props: {
    showWxLoginType: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data () {
    return {
      ACCOUNT_SHOW_TYPE
    };
  },
  computed: {
    showAccountType () {
      return this.$store.state.user.showAccountType;
    }
  },
  watch: {
    // 切换类型绑定事件
    showAccountType (val) {
      this.listenEnterKeyDown(val);
    }
  },
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.unListenEnterKeyDown();
  },
  destroyed () {
  },
  activated () {
  },
  methods: {
    initListenEnterKeyDown () {
      this.unListenEnterKeyDown();
      this.listenEnterKeyDown(this.showAccountType);
    },
    // 微信登录
    onWxLogin () {
      this.$refs.wxLogin.showModal();
    },
    // 重置数据
    resetParams () {
      switch (this.showAccountType) {
        case ACCOUNT_SHOW_TYPE.LOGIN_PASS.value:
          this.$refs.loginPass.resetParams();
          break;
        case ACCOUNT_SHOW_TYPE.LOGIN_SMS.value:
          this.$refs.loginSms.resetParams();
          break;
      }
    },
    // 监听回车按下事件
    listenEnterKeyDown (showAccountType) {
      this.unListenEnterKeyDown();
      showAccountType = Number(showAccountType);
      this.$nextTick(() => {
        switch (showAccountType) {
          case ACCOUNT_SHOW_TYPE.LOGIN_PASS.value:
            this.$refs.loginPass.listenEnterKeyDown();
            break;
          case ACCOUNT_SHOW_TYPE.LOGIN_SMS.value:
            this.$refs.loginSms.listenEnterKeyDown();
            break;
        }
      });
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown () {
      document.onkeydown = undefined;
    },
    // 切换到注册
    changeRegister () {
      // this.$store.commit('user/changeAccountType', ACCOUNT_SHOW_TYPE.REGISTER.value);
      this.$router.push({ path: '/register' });
      this.$store.commit('user/changeAccountModal', { isShow: false });
      // window.open('http://www.gzhfda-tc.org.cn/register')
    },
    // 切换到忘记密码
    changeForgetPass () {
      // this.$store.commit('user/changeAccountType', ACCOUNT_SHOW_TYPE.FORGET_PASS.value);
      this.$router.push({ path: '/forget-pass' });
      this.$store.commit('user/changeAccountModal', { isShow: false });
    },

    // 登录成功
    loginSuccess (userInfo) {
      if (!userInfo) {
        this.$emit('success');
        return;
      }
      this.$store.commit('user/saveUserInfo', userInfo);
      this.$emit('success');
    },
    clickTabs (value) {
      this.$store.commit('user/changeAccountType', value);
    }
  }
};
</script>
<style lang="less" scoped>
.account-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /*-----------tabs样式操作 start------------*/

  /deep/ .ivu-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }

  /deep/ .ivu-tabs-nav {
    height: 58px;
    display: flex;
  }

  /deep/ .ivu-tabs-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    color: #1c2634;

    &:last-child {
      margin-right: 0;
    }
  }

  /deep/ .ivu-tabs-tab-active {
    font-size: 16px;
    font-weight: 700;
    color: #1890ff;
  }

  /deep/ .ivu-tabs-ink-bar {
    height: 3px;
  }

  /*-----------tabs样式操作 end------------*/

  .account-body {
    flex: 1;
    overflow: hidden;
  }

  .wechat-login {
    width: 50px;
    margin: 0 auto 50px auto;
    text-align: center;
    font-size: 12px;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .wxlogo {
      width: 30px;
      height: 30px;
      margin-bottom: 6px;
    }
  }

  .lower {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .register {
      font-size: 14px;
      font-weight: 700;
      color: @main-color;
    }

    .forget-pwd {
      margin-left: auto;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
